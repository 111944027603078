/* eslint-disable no-console */
import { exists } from '../base/dom-utils.js';
import CollectionOfHotels from '../pages/collection-hotels.js';
import RenderFavourite from './render-favourite.js';
import {
  getStatesMapping,
  getDistinctCollectionsObject,
  getDistinctAmenitiesAndBrands,
  getItemFromDistinctCollections,
} from '../utils/filter-properties.js';
import Analytics from '../base/wyn-analytics-module.js';
import { _LANGUAGE_, $_PAGE_ } from '../base/vars.js';
import { getBaselineFilters, getBaselineParameters, getTripAdvisorIFrame } from '../base/utils.js';

class ListProperties {
  constructor() {
    if (exists('.listproperties')) {
      this.collectionOfHotels = null;
      this.lang = document.documentElement.lang;
      this.country = this.lang.split('_')[1];
      this.handleFiltersModal();
      this.hotelCollectionsData = [];
      this.displayedDataIndex = 0;
      this.imgData = {};
      this.recordsLimit = 50;
      this.currentOffset = 0;
      this.currentPage = 0;
      this.allBrands = [];
      this.selectedBrands = [];
      this.selectedFilters = [];
      this.allAmenities = [];
      this.selectedAmenities = [];
      this.limitedAmenities = [];
      this.locationsBackup = [];
      this.brandsBackup = [];
      this.amenititiesBackup = [];
      this.brandsCount = 0;
      this.ameniitiesCount = 0;
      this.locationsCount = 0;
      this.selectedLocations = [];
      this.filtersBackup = [];
      this.brandsLogos = {};
      this.fetchListCardData();
      this.applyFilters();
      this.handleCloseModal('.locations-filter-modal .close');
      this.locationAccordions = document.querySelectorAll('.locations-filter-panel a.accordion-toggle');
      this.popupCloseBtn = document.querySelector("#locationsFilterModal button.close[data-dismiss='modal']");
      this.getFilterOptions();
      this.displayedDataIndex = 0;
      this.recordsPerPage = 6;
      this.totalFetchedRecords = 0;
      this.listCardContainer = document.querySelector('.list-card-container');
      this.createIntersectionObserver();
      this.collapseAccordions();
      this.payload = {};
      this.handleClickAnalytics();
      this.searchParameters = getBaselineFilters();
      this.baselineQuery = getBaselineParameters();
      this.brandtierCodes = [];
      this.brandIdsCollated = [];
      this.curatedBrands = {};
      this.curatedAmenities = {};
      this.states = [];
      this.cities = [];
      this.mappingDataRequired = true;
      this.baselineDataMapping = {};
      this.brandsCheckboxToggled = false;
    }
  }

  getUpdateQueryParameters(options = {}) {
    this.mappingDataRequired = false;
    const payload = JSON.parse(JSON.stringify(this.baselineQuery));
    this.selectedLocations.forEach((s) => {
      if (s.category === 'region') {
        payload.countryCodes = [this.lang.split('_')[1]];
      } else if (s.category === 'country') {
        payload.countryCodes = [s.value];
      } else if (s.category === 'state') {
        payload.stateCodes = [s.value];
      } else if (s.category === 'city') {
        payload.filters = [
          {
            name: 'hotel_full_view.city',
            value: s.value,
          },
        ];
      }
    });
    const { brandIds, brandTierCode } = this.getBrandAndTierCode();
    if (brandIds) {
      payload.brandIds = this.brandIdsCollated ? brandIds.filter(b => this.brandIdsCollated.includes(b)) : brandIds;
    }
    if (brandTierCode) {
      payload.brandTierCode = this.brandIdsCollated ? brandTierCode.filter(b => this.brandIdsCollated.includes(b)) : brandTierCode;
    }
    if (this.selectedAmenities.length > 0) {
      if (!payload.hotelSearchableAmenityCodes) {
        payload.hotelSearchableAmenityCodes = [];
      }
      payload.hotelSearchableAmenityCodes.push(...this.selectedAmenities.map((a) => a.id));
    }
    if (options.searchCountry) {
      delete payload.stateCodes;
      delete payload.filters;
    }
    if (options.searchState) {
      delete payload.filters;
    }
    return payload;
  }

  async getUpdatedFilterOptions(options = {}) {
    $_PAGE_.addClass('loading');
    const updatedQueryParams = this.getUpdateQueryParameters(options);
    const distinctData = await this.getDistinctDataMapping(updatedQueryParams);
    this.updateFilterElements(distinctData);
    $_PAGE_.removeClass('loading');
    return distinctData;
  }

  updateFilterElements(updatedDistinctData) {
    $('#brands-cb').empty();
    $('#amenities-cb').empty();
    this.createBrandFiltersCheckboxes(this.curatedBrands);
    this.createAmenitiesFiltersCheckboxes(this.curatedAmenities);
    this.states = getStatesMapping(updatedDistinctData.distinctDataResponse) || [];
    this.cities = getItemFromDistinctCollections(getDistinctCollectionsObject(updatedDistinctData.distinctDataResponse), 'hotel_full_view.city') || [];
    const regions = Object.keys(this.baselineDataMapping.region || {}).map((region) => ({ label: region, value: region }));
    this.updateSelectElement('#dest-region', regions);
    const selectedRegion = document.querySelector('#dest-region').value || '';
    const countriesOptions = this.baselineDataMapping.region[selectedRegion] || {};
    const countries = Object.keys(countriesOptions).map((country) => ({
      value: country,
      label: countriesOptions[country],
    }));
    this.updateSelectElement('#dest-country', countries);
    const states = this.states
      .filter(this.filterStates.bind(this))
      .map((state) => ({ value: state.key, label: state.value }));
    this.updateSelectElement('#dest-state', states);
    const cities = this.cities.filter(this.filterCities.bind(this)).map((city) => ({ value: city, label: city }));
    this.updateSelectElement('#dest-city', cities);
  }

  updateSelectElement(elementSelector, items) {
    const dropdown = document.querySelector(elementSelector);
    if (dropdown) {
      const regionOptions = dropdown.querySelectorAll('option');
      regionOptions.forEach((regionOption) => regionOption.value && regionOption.remove());
      const regionFragment = new DocumentFragment();
      items.forEach(({ value, label }) => {
        const option = document.createElement('option');
        option.id = DOMPurify.sanitize(value);
        option.value = DOMPurify.sanitize(value);
        option.textContent = DOMPurify.sanitize(label);
        regionFragment.appendChild(option);
      });
      dropdown.appendChild(regionFragment);
    }
  }

  async getDistinctDataMapping(baselineParams) {
    try {
      const distinctDataResponse = await getDistinctAmenitiesAndBrands(baselineParams);
      const distinctCollection = getDistinctCollectionsObject(distinctDataResponse);
      if (distinctCollection) {
        this.brandIds = getItemFromDistinctCollections(distinctCollection, 'hotel.brand_id');
        this.countryCodes = getItemFromDistinctCollections(distinctCollection, 'hotel.country_code');
        this.amenityIds = getItemFromDistinctCollections(distinctCollection, 'hotel_full_view.unique_amenity_list');
        this.brandtierCodes = getItemFromDistinctCollections(distinctCollection, 'hotel_full_view.brand_tier_code');
        if(!this.brandsCheckboxToggled) {
          this.brandIdsCollated = [...this.brandIds, ...this.brandtierCodes];
          if(this.selectedBrands.length) {
            this.selectedBrands = this.selectedBrands.filter(b => this.brandIdsCollated.includes(b.id));
          }
        }
        this.brandsCheckboxToggled = false;
        if(this.mappingDataRequired) {
          const amenityIdsArray = this.amenityIds.join(',');
          const countryCodesArray = this.countryCodes.join(',');
          const brandIdsArray = this.brandIdsCollated.join(',');
          const servletBaseUrl = '/bin/brandamenitiesfilter';
          const servletQuery = `?country=${countryCodesArray}&brands=${brandIdsArray}&amenities=${amenityIdsArray}`;
          const locationFiltersResponse = await fetch(`${servletBaseUrl}${servletQuery}`);
          const responseJSON = await locationFiltersResponse.json();
          return { ...responseJSON, distinctDataResponse};
        }
        return { distinctDataResponse };
      }
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  initCarousel() {
    $('.list-card .list-card-carousel')
      .not('.slick-initialized')
      .slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 719,
            settings: {
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 374,
            settings: {
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      });
  }

  handlePageLoadAnalytics() {
    window.digitalData.search.searchInfo.searchResultsCount = this.totalCount;
  }

  async getFilterOptions() {
    try {
      const locationFilterData = await this.getDistinctDataMapping();
      this.baselineDataMapping = locationFilterData;
      this.curatedAmenities = locationFilterData.amenities || {};
      const tierCodes = locationFilterData.brandtier.reduce((acc, cur) => {
        const key = Object.keys(cur);
        const value = cur[key];
        if (!acc[key]) {
          acc[key] = value;
        }
        return acc;
      }, {});
      this.brandtierCodes = Object.keys(tierCodes).map((el) => el.toUpperCase());
      this.curatedBrands = { ...locationFilterData.brands, ...tierCodes };
      this.renderLocationFilters(locationFilterData);
      this.brandsLogos = locationFilterData.logo.reduce((acc, cur) => {
        acc[cur.brand] = cur.brandLogo;
        return acc;
      }, {});
    } catch (ex) {
      console.log('getFilterOptions exception :: ', ex);
    }
  }

  getAllProperties() {
    this.collectionOfHotels = new CollectionOfHotels();
    this.collectionOfHotels.hotels.then((data) => {
      this.propertiesRendererWrapper(data);
    });
  }

  fetchListCardData() {
    this.requestObject = {
      collectionType: 'whg:image-category/override-tags/golf',
    };
    const listCardInput = document.querySelector('.list-card');
    const collectionTypeInput = document.querySelector('.list-card .collectionType');
    if (collectionTypeInput) {
      const collectionTypeValue = collectionTypeInput.value;
      if (collectionTypeValue !== '') {
        this.requestObject.collectionType = collectionTypeValue;
      }
    }
    if (listCardInput) {
      this.getAllProperties();
    }
  }
  async getStarAmenities() {
    const data = await this.fetchOtherAmenities();
    return data;
  }
  async fetchOtherAmenities() {
    const url = '/bin/getStarAmenities';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }
  async propertiesRendererWrapper(apiResponse) {
    const imgIdsArr = [];
    const propertiesObj = [];
    const propertiesList = apiResponse && apiResponse.data && apiResponse.data.searchProperties && apiResponse.data.searchProperties.properties ? apiResponse.data.searchProperties.properties : [];
    this.totalCount = apiResponse.data.searchProperties.totalCount;
    $('#hotelCount').text(apiResponse.data.searchProperties.totalCount);
    $('#modal-hotel-count').text(apiResponse.data.searchProperties.totalCount);
    this.handlePageLoadAnalytics();
    const listCardContainer = document.querySelector('.list-card-container');
    listCardContainer.innerHTML = '';
    propertiesList.forEach((item) => {
      imgIdsArr.push({ brandId: item.brand.toLowerCase(), propertyId: item.propertyId });

      const amentiesArr = item.otherAmenities.map((item) => {
        return item.amenityName;
      });
      const obj = {
        language: _LANGUAGE_,
        startAmenities: amentiesArr,
        propertyId: item.propertyId,
      };
      propertiesObj.push(obj);
    });
    this.payload = propertiesObj;
    const resAmenties = await this.getStarAmenities();

    const keys = Object.keys(resAmenties);
    this.hotelCollectionsData = apiResponse && apiResponse.data && apiResponse.data.searchProperties && apiResponse.data.searchProperties.properties ? apiResponse.data.searchProperties.properties : [];
    keys &&
      keys.forEach((itemId) => {
        this.hotelCollectionsData.forEach((data) => {
          if (data.propertyId === itemId) {
            data['amenityName'] = resAmenties[itemId]['amenityName'];
          }
        });
      });
    this.requestObject.properties = imgIdsArr;
    this.collectionOfHotels.getImageTaggingData(this.requestObject).then((imgRes) => {
      $_PAGE_.removeClass('loading');
      this.imgData = imgRes;
      if (document.querySelector('.list-card-container')) {
        this.totalFetchedRecords = this.hotelCollectionsData.length;
        this.currentOffset++;
        this.displayMoreProperties();
      }
    });
  }

  createIntersectionObserver() {
    this.intersectionObserver = new IntersectionObserver(this.handleIntersect.bind(this), {
      root: null, // Use the document viewport
      rootMargin: '0px',
      threshold: 0.5,
    });
    if (this.intersectionObserver) {
      this.intersectionObserver.observe(document.querySelector('.footer'));
      this.collectionOfHotels.handleQuickViewModal('.list-card', '.quick-view-button');
    }
  }

  async loadInitialProperties() {
    try {
      const apiResponse = await this.collectionOfHotels.callCollectionAPI(
        this.currentOffset,
        this.recordsLimit,
        this.getUpdateQueryParameters()
      );
      this.hotelCollectionsData = apiResponse && apiResponse.data && apiResponse.data.searchProperties && apiResponse.data.searchProperties.properties ? apiResponse.data.searchProperties.properties : [];
      this.totalFetchedRecords = this.hotelCollectionsData.length;
      this.currentOffset++;
      this.displayMoreProperties(); // Display the first set of 6 properties
    } catch (error) {
      console.error('Error loading properties:', error);
    }
  }

  async loadMoreProperties() {
    try {
      if (this.displayedDataIndex >= this.totalFetchedRecords - 6 && this.totalCount > this.recordsLimit) {
        const apiResponse = await this.collectionOfHotels.callCollectionAPI(
          this.currentOffset,
          this.recordsLimit,
          this.getUpdateQueryParameters()
        );
        const newProperties = apiResponse && apiResponse.data && apiResponse.data.searchProperties && apiResponse.data.searchProperties.properties ? apiResponse.data.searchProperties.properties : [];
        this.hotelCollectionsData.push(...newProperties);
        this.totalFetchedRecords += newProperties.length;
        this.currentOffset++;
      }
      this.displayMoreProperties(); // Display the next set of 6 properties
    } catch (error) {
      console.error('Error loading properties:', error);
    }
  }

  displayMoreProperties() {
    const start = this.currentPage * this.recordsPerPage;
    const end = (this.currentPage + 1) * this.recordsPerPage;
    const propertiesToDisplay = this.hotelCollectionsData.slice(start, end);
    if (propertiesToDisplay.length > 0) {
      this.renderListProperties(propertiesToDisplay);
      this.displayedDataIndex += this.recordsPerPage;
      this.currentPage++;
    }
  }

  renderListProperties(properties) {
    properties.forEach((item) => {
      this.renderListCard(item, this.listCardContainer);
      this.initCarousel(); // Ensure this is defined if needed
    });
    this.collectionOfHotels.handleQuickViewModal('.list-card', '.quick-view-button');
  }

  handleIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMoreProperties();
      }
    });
  }

  getRecommendation(cardItem) {
    const additionalDetails = cardItem.additionalDetails;
    if (!Array.isArray(additionalDetails)) {
      return 'All Inclusive'; // or any other default string
    }
    const detail = additionalDetails.find(
      ({ name, values }) => name === 'hotelQualityScoreRequirementFlag' && Array.isArray(values) && values.includes('Y')
    );
    return detail ? 'Guest Recommended' : cardItem['amenityName'] ? cardItem['amenityName'] : '';
  }
  renderListCard(cardItem, container) {
    const brandId = cardItem.brand.toLowerCase();
    const cardListElement = document.createElement('div');
    cardListElement.classList.add('list-card-list');

    const cardImageElement = document.createElement('div');
    cardImageElement.classList.add('list-card-image');

    const carouselElement = document.createElement('div');
    carouselElement.classList.add('list-card-carousel');
    cardImageElement.appendChild(carouselElement);

    const eyebrowElement = document.createElement('div');
    eyebrowElement.classList.add('list-card-eyebrow');
    eyebrowElement.textContent = this.getRecommendation(cardItem);
    this.getRecommendation(cardItem) && cardImageElement.appendChild(eyebrowElement);
    RenderFavourite.fetchFavourite(cardImageElement, cardItem.propertyId);
    const cardDetailsElement = document.createElement('div');
    cardDetailsElement.classList.add('list-card-details');

    const headlineElement = document.createElement('h3');
    headlineElement.classList.add('headline-h');
    headlineElement.textContent = cardItem.name;
    cardDetailsElement.appendChild(headlineElement);

    const descriptionElement = document.createElement('div');
    descriptionElement.classList.add('list-card-description');

    const locationElement = document.createElement('p');
    locationElement.classList.add('location-text');
    locationElement.textContent = `${cardItem.address.city}, ${cardItem.address.state}`;
    descriptionElement.appendChild(locationElement);

    const ratingElement = document.createElement('div');
    ratingElement.classList.add('rating');
    ratingElement.setAttribute('id', cardItem.propertyId);
    const sanitizedtaIframe = getTripAdvisorIFrame(cardItem.propertyId);
    ratingElement.innerHTML = sanitizedtaIframe;
    descriptionElement.appendChild(ratingElement);

    const descriptionTextElement = document.createElement('div');
    descriptionTextElement.classList.add('list-card-details-description-text');
    descriptionTextElement.textContent = cardItem.headline;
    descriptionElement.appendChild(descriptionTextElement);
    const buttonGroupElement = document.createElement('div');
    buttonGroupElement.classList.add('list-card-details-btn-group');
    const brandIconElement = document.createElement('div');
    brandIconElement.classList.add('btn-secondary');
    brandIconElement.classList.add('brand-logo');
    brandIconElement.innerHTML = `<img src="${this.brandsLogos[brandId]}" title="${this.curatedBrands && this.curatedBrands[brandId] || ''}" />`;
    buttonGroupElement.appendChild(brandIconElement);

    const quickViewBtnElement = document.createElement('a');
    quickViewBtnElement.classList.add('btn-secondary', 'quick-view-button');
    quickViewBtnElement.href = DOMPurify.sanitize(cardItem.uniqueUrl);
    quickViewBtnElement.dataset.id = DOMPurify.sanitize(cardItem.propertyId);
    quickViewBtnElement.textContent = 'Quick View';
    buttonGroupElement.appendChild(quickViewBtnElement);

    cardDetailsElement.appendChild(descriptionElement);
    cardDetailsElement.appendChild(buttonGroupElement);

    cardListElement.appendChild(cardImageElement);
    cardListElement.appendChild(cardDetailsElement);

    const imageUrls = this.imgData[cardItem.propertyId] || [];
    imageUrls.forEach((imageUrl) => {
      const pictureElement = document.createElement('figure');
      pictureElement.classList.add('img-wrap');

      const imageItem = document.createElement('img');
      imageItem.alt = imageUrl.altText;
      imageItem.src = imageUrl.desktop;      
      imageItem.classList.add('pll-image-full-size', 'lazy-load-pageload', 'carousel-img', 'list-card-carousel-image');
      pictureElement.appendChild(imageItem);

      carouselElement.appendChild(pictureElement);
    });

    container.appendChild(cardListElement);
  }

  /**
   function to update UI elements when user opens the popup or click on reset filter
   */
  updateFilterUI() {
    const dropdowns = document.querySelectorAll('select[id^=dest-]'); // select dropdowns
    const selectedLocations = this.selectedLocations.map((e) => e.id); // IDs of each select item from dropdown
    const brandsCheckboxes = document.querySelectorAll('input[data-type="brands"]');
    const activeBrandsCheckBoxes = this.selectedBrands.map((e) => e.id);
    const amenitiesCheckboxes = document.querySelectorAll('input[data-type="amenities"]');
    const activeAmenitiesCheckBoxes = this.selectedAmenities.map((e) => e.id);
    brandsCheckboxes.forEach((c) => {
      const { id } = c;
      c.checked = activeBrandsCheckBoxes.includes(id);
    });
    amenitiesCheckboxes.forEach((c) => {
      const { id } = c;
      c.checked = activeAmenitiesCheckBoxes.includes(id);
    });
    dropdowns.forEach((c) => {
      let found = false;
      for (let opt of c.options) {
        const { id } = opt;
        if (selectedLocations.includes(id)) {
          const item = this.selectedLocations.find((l) => l.id === id);
          if (item) {
            c.value = item.value;
            found = true;
            break;
          }
        }
      }
      if (!found) {
        c.selectedIndex = 0;
        if (!c.id.includes('region')) {
          c.disabled = true;
          c.options.length = 1;
        }
      }
    });
  }

  handleOutsideClick() {
    let intervalRef = null;
    intervalRef = setInterval(() => {
      const element = document.querySelector('.modal.fade.locations-filter-modal');
      if (element) {
        clearInterval(intervalRef);
        element.addEventListener('click', (event) => {
          if (event.target === element) {
            this.restoreFiltersToPreviousState();
          }
        });
      }
    }, 200);
  }

  /**
   function to handle popup open event and accordingly update the UI elements
   */
  handleFiltersModal() {
    ['btnLocations', 'btnBrands', 'btnAmenities', 'btnFilters'].forEach((btn) => {
      const btnElem = document.querySelector(`#${btn}`);
      if (btnElem) {
        btnElem.addEventListener('click', (event) => {
          this.locationsBackup = this.selectedLocations.map((e) => ({ ...e }));
          this.brandsBackup = this.selectedBrands.map((e) => ({ ...e }));
          this.amenititiesBackup = this.selectedAmenities.map((e) => ({ ...e }));
          this.filtersBackup = this.selectedFilters.map((e) => ({ ...e }));
          this.updateFilterUI();
          const { panel } = event.target.dataset;
          const accordionBtn = document.querySelector(`.panel.locations-filter-panel.${panel} a.accordion-toggle`);
          if (accordionBtn) {
            accordionBtn.click();
          }
          if (this.popupCloseBtn) {
            this.popupCloseBtn.dataset.ignoreClick = '';
          }
          this.handleOutsideClick();
        });
      }
    });
  }
  toggleApplyFilterBtnState() {
    if (this.filtersBackup && this.selectedFilters) {
      const existingFilters = this.filtersBackup.map((f) => f.id);
      const newFilters = this.selectedFilters.map((f) => f.id);
      const isChanged =
        existingFilters.length !== newFilters.length ||
        existingFilters.some((e) => !newFilters.includes(e)) ||
        newFilters.some((n) => !existingFilters.includes(n));
      if (isChanged) {
        $('#apply-filter').removeAttr('disabled');
      } else {
        $('#apply-filter').attr('disabled', 'disabled');
      }
    }
  }

  populateFiltersData() {
    const amenities = new Set();
    const brands = new Set();
    this.hotelCollectionsData.forEach((item) => {
      brands.add(item.brand);
      item.address.forEach((subItem) => {
        subItem.city.forEach((amenity) => {
          amenities.add(amenity.amenityName);
        });
      });
    });
    this.allBrands = Array.from(brands).sort();
    this.allAmenities = Array.from(amenities);
    this.allAmenities.sort();
    this.limitedAmenities = this.allAmenities.slice(0, 20);

    this.createBrandFiltersCheckboxes();
    this.createAmenitiesFiltersCheckboxes();
  }

  generateCheckbox(type, id, value, options) {
    const { isChecked, isAuthored, notAvailable } = options;
    const dataType = type === 'amen' ? 'amenities' : 'brands';
    const classes = `checkbox empty ${type} ${notAvailable ? 'not-available disabled' : ''} ${isChecked || isAuthored ? 'checked' : ''}`;
    return `<div class="checkbutton">
              <div class="checkbox ${type}">
                <label>
                  <input
                    id='${id}'
                    name="type"
                    type="checkbox"
                    placeholder=""
                    value="${value}"
                    class="${classes}"
                    aria-required="false"
                    autocapitalize="none"
                    data-type="${dataType}"
                    ${isAuthored || isChecked ? 'checked="checked"' : ''}
                    ${isAuthored || notAvailable ? 'disabled="disabled"' : ''}
                  />
                  <div class="custom-checkbox">
                    ${isAuthored ? '<span class="lock-icon" />' : ''}
                    <span>${value}</span>
                  </div>
                </label>
              </div>
              <span class="equalize-offset-element"></span>
            </div>`;
  }

  /**
   function to create checkboxes for brands
  */
  createBrandFiltersCheckboxes(allBrands) {
    let brandTemplate = '';
    const collatedBrands = this.brandIdsCollated && this.brandIdsCollated.length && this.brandIdsCollated.map(b => b.toLowerCase());
    Object.keys(allBrands).forEach((id) => {
      const item = allBrands[id];
      let isChecked = false;
      let notAvailable = false;
      if (
        this.selectedBrands &&
        this.selectedBrands.length &&
        this.selectedBrands.find((e) => e.id.toLowerCase() === id.toLowerCase())
      ) {
        isChecked = true;
      }
      if (collatedBrands && !collatedBrands.includes(id)) {
        notAvailable = true;
      }
      brandTemplate += this.generateCheckbox('br', id.toUpperCase(), item, {isChecked, notAvailable});
    });
    $('#brands-cb').append(brandTemplate);
    $('.checkbox.br input[type="checkbox"]').on('change', this.handleCheckboxChange.bind(this, 'brand'));
  }
  /**
   function to create checkboxes for amenities
   */
  createAmenitiesFiltersCheckboxes(allAmenities) {
    let amenitiesTemplate = '';
    Object.keys(allAmenities).forEach((id) => {
      const item = allAmenities[id];
      let isChecked = false;
      let isAuthored = false;
      let notAvailable = false;
      if (this.selectedAmenities && this.selectedAmenities.length && this.selectedAmenities.find((e) => e.id === id)) {
        isChecked = true;
      }
      if (this.searchParameters && this.searchParameters.hotelSearchableAmenityCodes) {
        isAuthored = this.searchParameters.hotelSearchableAmenityCodes.includes(id);
      }
      if (this.amenityIds && this.amenityIds.length && !this.amenityIds.includes(id)) {
        notAvailable = true;
      }
      amenitiesTemplate += this.generateCheckbox('amen', id, item, {isChecked, isAuthored, notAvailable});
    });
    $('#amenities-cb').append(amenitiesTemplate);
    $('.checkbox.amen input[type="checkbox"]').on('change', this.handleCheckboxChange.bind(this, 'amen'));
  }
  /**
   function to handle toggle of checkboxes for brands and amenities filter
   */
  handleCheckboxChange(category, event) {
    const checked = event.target.checked;
    const value = event.target.value;
    const id = event.target.id;
    let filterObj = { id: id, value: value, category: category };
    if (checked) {
      this.addFilterOption(filterObj);
    } else {
      this.removeFilterOption(filterObj);
    }
    this.brandsCheckboxToggled = category === 'brand';
    this.toggleApplyFilterBtnState();
    this.getUpdatedFilterOptions();
  }
  /**
   function to add filter option from the list.
   */
  addFilterOption(filterObj) {
    const cat = filterObj.category;
    if (cat === 'brand') {
      this.selectedBrands.push(filterObj);
    } else if (cat === 'amen') {
      this.selectedAmenities.push(filterObj);
    } else if (cat === 'region') {
      this.selectedLocations = [filterObj];
    } else if (['country', 'state', 'city'].includes(cat)) {
      this.selectedLocations = this.selectedLocations.filter((s) => s.category !== cat);
      if (cat === 'country') {
        this.selectedLocations = this.selectedLocations.filter((s) => !['state', 'city'].includes(s.category));
      } else if (cat == 'state') {
        this.selectedLocations = this.selectedLocations.filter((s) => !['city'].includes(s.category));
      }
      this.selectedLocations.push(filterObj);
    }
    this.selectedFilters.push(filterObj);
    this.updateCountBadge();
  }
  /**
   function to remove filter option from the list.
   */
  removeFilterOption(filterObj) {
    const { category: cat, id } = filterObj;
    const filterCb = (item) => item.id !== id;
    if (cat === 'brand') {
      this.selectedBrands = this.selectedBrands.filter(filterCb);
    } else if (cat === 'amen') {
      this.selectedAmenities = this.selectedAmenities.filter(filterCb);
    } else if (['region', 'country', 'state', 'city'].includes(cat)) {
      this.selectedLocations = this.selectedLocations.filter((item) => item.category !== cat);
    }
    this.selectedFilters = this.selectedFilters.filter(filterCb);
    this.updateCountBadge();
    this.removeClearFilterBtn();
  }
  removeClearFilterBtn() {
    if (!this.selectedBrands.length && !this.selectedAmenities.length && !this.selectedLocations.length) {
      const resetFilterBtn = document.querySelector('#filterReset');
      if (resetFilterBtn) {
        resetFilterBtn.remove();
      }
      $('#apply-filter').attr('disabled', 'disabled');
      this.toggleSelectedFiltersSection(false);
    }
  }

  updateApplyFilterState() {}
  /**
   function to update badge count
   */
  updateCountBadge() {
    this.updateFilterCount('brands-count', this.selectedBrands.length);
    this.updateFilterCount('amenities-count', this.selectedAmenities.length);
    this.updateFilterCount('destination-count', this.selectedLocations.length);
  }
  /**
   function to update UI of badge count.
   */
  updateFilterCount(elClass, count) {
    const selectedDiv = document.getElementsByClassName(elClass);
    for (let i = 0; i < selectedDiv.length; i++) {
      const parentDiv = selectedDiv[i].parentElement;
      if (parentDiv) {
        parentDiv.style.display = count === 0 ? 'none' : 'inline-block';
        selectedDiv[i].textContent = `${count}`;
      }
    }
  }
  /**
   function to add close event listener to selected filter.
   */
  handleClose() {
    $('.selected-filter-list')
      .off('click')
      .on('click', '.close-icon', (event) => {
        const id = event.target.dataset.elem;
        // $('#' + id).prop('checked', false);
        const ids = id.split('-');
        let removeObj = this.selectedFilters.find((obj) => {
          return obj.id === ids[1];
        });
        if (removeObj) {
          this.removeFilterOption(removeObj);
        }
        switch (ids[0]) {
        case 'brand':
        case 'amen':
          this.handleBrandRemove(ids[1]);
          break;
        case 'region':
        case 'country':
        case 'state':
        case 'city':
          this.handleLocationRemove(ids[0]);
          break;
        }
        $(event.target).closest('li').remove();
      });
  }
  /**
   function to handle close btn for selected locations.
   */
  handleLocationRemove(deletedCategory) {
    const allCategories = ['region', 'country', 'state', 'city'];
    const categoryIndex = allCategories.indexOf(deletedCategory);
    const categoriesToRemove = allCategories.filter((_, index) => index >= categoryIndex);
    categoriesToRemove.forEach((c, index) => {
      const elem = document.querySelector(`#dest-${c}`);
      if (elem) {
        elem.selectedIndex = 0;
        if (c !== 'region' || c !== deletedCategory) {
          elem.disabled = true;
        }
      }
    });
    const itemsToRemove = this.selectedLocations.filter((s) => categoriesToRemove.includes(s.category));
    itemsToRemove.forEach((item) => {
      const itemToRemove = `${item.category}-${item.id}`;
      const uiElem = $(`#${itemToRemove}`);
      if (uiElem) {
        uiElem.closest('li').remove();
      }
    });
    this.selectedLocations = this.selectedLocations.filter((s) => !categoriesToRemove.includes(s.category));
    this.updateFilterCount('destination-count', this.selectedLocations.length);
    this.hotelCollectionsData = [];
    const listCardContainer = document.querySelector('.list-card-container');
    listCardContainer.innerHTML = '';
    this.removeClearFilterBtn();
    this.getFilteredProperties();
  }
  /**
   function to handle close btn for selected brand and aminites.
   */
  handleBrandRemove(brandId) {
    const elem = document.querySelector(`#${brandId}`);
    if (elem) {
      elem.checked = false;
    }
    this.getFilteredProperties();
  }
  /**
   function to generate option element for location filter
   */
  generateOptionsList(firstOptionLabel, options, objectType = false) {
    const firstOption = `<option value disabled selected>${firstOptionLabel}</option>`;
    const optionElements = options.map((option) => {
      let id = '';
      let key = option;
      let value = option;
      if (objectType) {
        id = option.key || id;
        key = option.key || option;
        value = option.value || option;
      } else {
        id = option.replace(/\s/g, '');
      }
      return `<option id="${id}" value="${key}">${value}</option>`;
    });
    return firstOption + optionElements.join('');
  }

  filterCountries(country) {
    const shouldBeIncluded =
      !this.searchParameters.countryCodes.length || this.searchParameters.countryCodes.includes(country);
    return shouldBeIncluded;
  }

  filterStates({ key, value }) {
    const curatedStates = this.searchParameters.stateCodes;
    const shouldBeIncluded = key && value && !key.includes('*') && !value.includes('*');
    return shouldBeIncluded && (!curatedStates.length || curatedStates.includes(key));
  }

  filterCities(city) {
    const curatedCities = this.searchParameters.cityNames;
    const shouldBeIncluded = city && !city.includes('*');
    return shouldBeIncluded && (!curatedCities.length || curatedCities.includes(city));
  }

  /**
   function to get filter data from API and render initial state of filter.
   */
  renderLocationFilters(data) {
    try {
      this.createBrandFiltersCheckboxes(this.curatedBrands);
      this.createAmenitiesFiltersCheckboxes(data.amenities);
      const regions = Object.keys(data.region || {});
      const regionDropdown = document.querySelector('#dest-region');
      const countryDropdown = document.querySelector('#dest-country');
      const stateDropdown = document.querySelector('#dest-state');
      const cityDropdown = document.querySelector('#dest-city');
      let currentRegion = '';
      let currentCountry = '';
      let countries = {};
      let currentState = '';
      let currentCity = '';
      countryDropdown.disabled = true;
      stateDropdown.disabled = true;
      cityDropdown.disabled = true;
      const regionOptions = regionDropdown.querySelectorAll('option');
      regionOptions.forEach((regionOption) => regionOption.remove());
      const tagenerateOptionsList = this.generateOptionsList(regionDropdown.getAttribute('aria-label'), regions);
      const sanitizedtagenerateOptionsList = DOMPurify.sanitize(tagenerateOptionsList);
      regionDropdown.innerHTML = sanitizedtagenerateOptionsList;
      regionDropdown.addEventListener('change', (event) => {
        const { value = '', selectedIndex, options } = event.target;
        stateDropdown.disabled = true;
        cityDropdown.disabled = true;
        const { id } = options[selectedIndex];
        if (currentRegion) {
          this.removeFilterOption({ id, category: 'region' });
        }
        if (value) {
          currentRegion = value;
          this.addFilterOption({ id, value: currentRegion, category: 'region' });
          countryDropdown.disabled = false;
          countries = data.region[value] || {};
          const countryIDs = Object.keys(countries).filter(this.filterCountries.bind(this));
          const countryOptionsHTML = countryIDs.map(
            (countryId) => `<option id="${countryId}" value="${countryId}">${countries[countryId]}</option>`
          );
          const countryOptions = countryDropdown.querySelectorAll('option');
          countryOptions.forEach((countryOption) => countryOption.remove());
          countryDropdown.innerHTML = DOMPurify.sanitize(
            `<option value disabled selected>${countryDropdown.getAttribute('aria-label')}</option>` +
              countryOptionsHTML.join('')
          );
        } else {
          countryDropdown.disabled = true;
        }
        this.toggleApplyFilterBtnState();
        this.getUpdatedFilterOptions({ searchCountry: true });
      });
      countryDropdown.addEventListener('change', (event) => {
        const { value = '', options, selectedIndex } = event.target;
        const { id, textContent } = options[selectedIndex];
        this.selectedCountry = id;
        cityDropdown.disabled = true;
        if (currentCountry) {
          this.removeFilterOption({ id, category: 'country' });
        }
        if (value) {
          currentCountry = value;
          this.addFilterOption({
            id,
            value: currentCountry,
            category: 'country',
            itemValue: textContent,
          });
          stateDropdown.disabled = true;
          stateDropdown.innerHTML = this.generateOptionsList(stateDropdown.getAttribute('aria-label'), []);
          this.getUpdatedFilterOptions({ searchCountry: true }).then((updatedResponse) => {
            stateDropdown.disabled = false;
          });
        }
        this.toggleApplyFilterBtnState();
      });
      stateDropdown.addEventListener('change', (event) => {
        const { value = '', selectedIndex, options } = event.target;
        const { id, textContent } = options[selectedIndex];
        if (currentState) {
          this.removeFilterOption({ id, category: 'state' });
        }
        if (value) {
          currentState = value;
          this.addFilterOption({ id, value: currentState, category: 'state', itemValue: textContent });
        }
        cityDropdown.disabled = true;
        cityDropdown.innerHTML = this.generateOptionsList(cityDropdown.getAttribute('aria-label'), []);
        this.getUpdatedFilterOptions({ searchState: true }).then((updatedResponse) => {
          cityDropdown.disabled = false;
        });
        this.toggleApplyFilterBtnState();
      });
      cityDropdown.addEventListener('change', (event) => {
        const { value = '', selectedIndex, options } = event.target;
        const { id, textContent } = options[selectedIndex];
        if (currentCity) {
          this.removeFilterOption({ id, category: 'city' });
        }
        if (value) {
          currentCity = value;
          this.addFilterOption({ id, value: currentCity, category: 'city', itemValue: textContent });
        }
        this.toggleApplyFilterBtnState();
        this.getUpdatedFilterOptions();
      });
    } catch (e) {
      console.log('get location data error', e);
    }
  }

  collapseAccordions() {
    this.locationAccordions.forEach((el) => {
      if (!el.classList.contains('collapsed')) {
        el.click();
      }
    });
  }

  getBrandAndTierCode() {
    let brandIds = null;
    let brandTierCode = null;
    if (this.selectedBrands.length) {
      const allBrands = this.selectedBrands.map((s) => s.id);
      allBrands.forEach((bid) => {
        if (this.brandtierCodes.includes(bid)) {
          if (!brandTierCode) {
            brandTierCode = [];
          }
          brandTierCode.push(bid);
        } else {
          if (!brandIds) {
            brandIds = [];
          }
          brandIds.push(bid);
        }
      });
    }
    return { brandIds, brandTierCode };
  }

  getFilteredProperties() {
    this.currentPage = 0;
    this.currentOffset = 0;
    this.displayedDataIndex = 0;
    this.collectionOfHotels
      .callCollectionAPI(this.currentOffset, this.recordsLimit, this.getUpdateQueryParameters())
      .then((response) => this.propertiesRendererWrapper(response))
      .catch((error) => console.log('getFilteredProperties error ', error.message));
  }
  /**
   function to be called when apply button will be clicked. It also take care of modal close button handling.
   */
  applyFilters() {
    $('#apply-filter').on('click', (event) => {
      event.preventDefault();
      $('.selected-filter-list').empty();
      this.hotelCollectionsData = [];
      const listCardContainer = document.querySelector('.list-card-container');
      listCardContainer.innerHTML = '';
      this.renderListCards();
      if (this.popupCloseBtn) {
        this.popupCloseBtn.dataset.ignoreClick = 'true';
        this.popupCloseBtn.click();
      }
      this.collapseAccordions();
      this.getFilteredProperties();
      let locationsStr = '';
      let brandsStr = '';
      let amenitiesStr = '';
      let refinementSelection = '';
      let refinementType = '';
      let categories = [];
      if (this.selectedLocations.length > 0) {
        locationsStr = this.selectedLocations.map((obj) => obj.value).join(',');
        categories.push('Destinations');
      }
      if (this.selectedBrands.length > 0) {
        brandsStr = this.selectedBrands.map((obj) => obj.value).join(',');
        categories.push('Brands');
      }
      if (this.selectedAmenities.length > 0) {
        amenitiesStr = this.selectedAmenities.map((obj) => obj.value).join(',');
        categories.push('Amenities');
      }
      refinementSelection = [locationsStr, brandsStr, amenitiesStr].filter((str) => str !== '' && str !== '').join('|');
      refinementType = categories.join('|');
      window.digitalData.search.filter.refinementType = refinementType;
      window.digitalData.search.filter.refinementSelection = refinementSelection;
      Analytics.satelliteTracker('filtersApplied');
    });
  }

  restoreFiltersToPreviousState() {
    this.selectedLocations = this.locationsBackup.map((e) => ({ ...e }));
    this.selectedBrands = this.brandsBackup.map((e) => ({ ...e }));
    this.selectedAmenities = this.amenititiesBackup.map((e) => ({ ...e }));
    this.selectedFilters = this.filtersBackup.map((e) => ({ ...e }));
    this.collapseAccordions();
    this.updateCountBadge();
    $('.selected-filter-list').empty();
    this.renderListCards();
    this.removeClearFilterBtn();
  }

  handleCloseModal(elementSelector) {
    $(elementSelector)
      .off('click')
      .on('click', (event) => {
        if (!event.target.dataset.ignoreClick) {
          this.restoreFiltersToPreviousState();
        }
      });
  }
  /**
   function to create a single filter item
   */
  getTagItem(tagObject) {
    const { id, value, itemValue, category } = tagObject;
    return `<li>
              <span class="option-name" id='${category}-${id}'>${itemValue || value}</span>
              <button class="close-icon" data-elem="${category}-${id}"></button>
            </li>`;
  }
  /**
   function to update UI after user clicks on apply button
   we render each setof selected filters and accordingly add a reset filter button.
   we also make sure that close handler is added for each selected filter.
   */
  renderListCards() {
    const tagContainer = $('.selected-filter-list');
    this.selectedLocations.forEach((tag) => {
      tagContainer.append(this.getTagItem(tag));
    });
    this.selectedBrands.forEach((tag) => {
      tagContainer.append(this.getTagItem(tag));
    });
    this.selectedAmenities.forEach((tag) => {
      tagContainer.append(this.getTagItem(tag));
    });
    if (this.selectedLocations.length || this.selectedBrands.length || this.selectedAmenities.length) {
      tagContainer.append('<li><button class="btn-link" id="filterReset">Reset filters</button></li>');
      this.toggleSelectedFiltersSection(true);
    }
    this.handleClose();
    this.resetFilters();
  }
  /**
   function to handle reset filter functionalites
   we clear all selected filters and update count badge count and then update the UI elements to clear any selected value.
   */
  resetFilters() {
    $('#filterReset')
      .off('click')
      .on('click', (e) => {
        Analytics.satelliteTracker('resetFiltersClick');
        this.selectedLocations = [];
        this.selectedBrands = [];
        this.selectedAmenities = [];
        this.selectedFilters = [];
        this.updateCountBadge();
        $('.selected-filter-list').empty();
        this.updateFilterUI();
        this.removeClearFilterBtn();
        this.brandIdsCollated = null; // should contain all brand IDs,
        this.amenityIds = null; // should contain all amenity IDs,
        this.brandsCheckboxToggled = false;
        $('#brands-cb').empty();
        $('#amenities-cb').empty();
        this.createBrandFiltersCheckboxes(this.curatedBrands);
        this.createAmenitiesFiltersCheckboxes(this.curatedAmenities);
        this.currentPage = 0;
        this.currentOffset = 0;
        this.displayedDataIndex = 0;
        this.getAllProperties();
      });
  }

  toggleSelectedFiltersSection(showFiltersArea) {
    const element = document.querySelector('.selected-filter-list-panel');
    if (element) {
      if (showFiltersArea) {
        element.classList.add('show');
      } else {
        element.classList.remove('show');
      }
    }
  }

  handleClickAnalytics() {
    const carouselArrows = document.querySelectorAll('.list-card .slick-arrow');
    if (carouselArrows.length > 0) {
      carouselArrows.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          Analytics.satelliteTracker('carausalArrowClick');
        });
      });
    }
    $('.collection-hotels-page .text-offer-card .card-button').on('click', () => {
      Analytics.satelliteTracker('bookNowClick');
    });
    $('.selectedFiltersDropdown').on('click', () => {
      Analytics.satelliteTracker('selectedFiltersClick');
    });
  }
}

export default new ListProperties();
