import { $_PAGE_, brand_id } from '../../base/vars.js';
import {
  BBLoad,
  BBExpandCollapse
} from '../../components/booking-bar.js';
import HeaderMobile from './header-mobile.js';
import Header from './header.js';
import {
  ResizeHandler,
  isDesktopWidth,
  isMobileWidth,
  isTabletWidth,
  exists
} from '../../base/dom-utils.js';

class BookingBar {
  constructor() {
    BBLoad.bookingBarLoad();

    this._el = '.booking-bar-form.booking-bar-main';
    this.$bb = $(this._el).not('.secondary-form');

    if ($('.extended-hero-container').length) {
      this.$extendedHero = $('.extended-hero-container');
    } else {
      this.$extendedHero = $('.extended-hero-component');
    }

    this.reservationsDropdownDesktop = '.dropdown[data-dropdown=reservations]';
    this.$reservationsDropdownDesktop = $(this.reservationsDropdownDesktop);
    this.reservationsDropdownMobile = '.dropdown[data-dropdown=book]';
    this.$reservationsDropdownMobile = $(this.reservationsDropdownMobile);
    this.$reservationsDropdowns = $(`${this.reservationsDropdownDesktop},${this.reservationsDropdownMobile}`);

    this.$reservationsDropdowns.on('wr:dropdown:open', this.handleBookingBarInHeader.bind(this))
      .on('wr:dropdown:closed', this.putBookingBarIntoExtendedHero.bind(this));

    if (this.$extendedHero.is('.has-booking-bar')) {
      this.handleExtendedHero();

      ResizeHandler.addResizeEndFn(() => {
        Header.closeDropdowns();
        HeaderMobile.closeDropdowns();

        if (isDesktopWidth() || isTabletWidth()) {
          this.putBookingBarIntoExtendedHero();
        } else {
          this.putBookingBarIntoMobileDropdown();
        }
      });
    }

    // WRM-4141
    //  jQuery UI and Bootstrap Dropdowns are not playing nice together
    //    Bootstrap Dropdowns are preventing propagation of the click to the document root
    //      Presumably this is how dropdown closing is handled, or something
    //    However, jQuery needs to capture a click on the doc root in order to reset a boolean
    //      that captures whether an element was clicked or not
    //    By firing a click on the document when the jQuery UI element in question is clicked
    //      we can validly reset the jQuery boolean value without closing the Bootstrap Dropdown.
    $('.dropdown form').on('click', '.ui-menu-item', () => {
      $(document).click();
    });
  }

  findBookingSearchChannels(data) {
    for (const item of data) {
      if (item.hasOwnProperty('bookingSearchChannels')) {
        return item.bookingSearchChannels;
      }
    }
    return null;
  }

  putBookingBarIntoExtendedHero() {
    window.wyndham.configs = window.wyndham.configs || {};
    let hero;
    if(brand_id == 'wr' ||  ($_PAGE_.is('.homepage') && !(isMobileWidth()))){
      hero = this.$extendedHero;
      if (this.$extendedHero.parents('.authenticationcontainer').length && $_PAGE_.is('.homepage')) {
        // Authentication container has multiple extended hero components.
        // Limit to the visible one to avoid breaking dropdowns
        hero = this.$extendedHero.filter(':visible');
      }
      hero = hero.filter('.has-booking-bar');

    }else{
      hero = $('.main-booking-bar-append');
    }

    // show RTP Awareness component (would not impact the desktop visibility of the component)
    this.$bb.find('li.rtp-awareness').closest('div').show();

    if (!(isMobileWidth()) && this.$bb.parents('.modal.in').length === 0) {
      hero.append(this.$bb.detach());
      if(window.wyndham.configs && window.wyndham.configs.hasOwnProperty("booking-search-configuration")){
        const channels = this.findBookingSearchChannels(window.wyndham.configs["booking-search-configuration"].configs);
        if (channels !== null && (channels.includes("desktop") || channels.includes("tablet"))){
          if($_PAGE_.is('.homepage-template') && $(".extended-hero-container.height-tall.has-booking-bar").length > 0){
            $(".extended-hero-container.height-tall.has-booking-bar").addClass("wr-pin-to-top");
            $(".homepage.page.extended-hero:not(.scrolled) header").css("border", "0 none");
            $(window).scroll(()=> {
              if($(window).scrollTop() >= $('.desktop-nav').height()) {
                $(".extended-hero-container.height-tall.has-booking-bar.wr-pin-to-top .booking-bar-form").addClass('extended-hero-sticky-bb');
              } else{
                $(".extended-hero-container.height-tall.has-booking-bar.wr-pin-to-top .booking-bar-form").removeClass('extended-hero-sticky-bb');
              }
            });
          }
        }
      }
    } else {
      hero.append(this.$bb.detach());
      $_PAGE_.removeClass('scrolled');
      window.scrollTo(window.scrollX, window.scrollY + 1);
      if(!$_PAGE_.is('.homepage')){
       $('#magnifyingGlassButton').triggerHandler('click');
      }
    }

    if(window.brand_id  != 'wr' && exists($('.book-with-points-header'))) {
      $('.more-options-container .dropdown-overflow' , this.$bb).prepend($('.book-with-points-header div.wyndham-rewards-checkbox',this.$bb).detach());
      $('.wyndham-rewards-checkbox-prompt' , this.$bb).show();
    }

  }

  putBookingBarIntoDesktopDropdown() {
    BBExpandCollapse.collapse();

    this.$reservationsDropdownDesktop.find('.mm-booking-form-container').append(this.$bb.detach());
  }

  putBookingBarIntoMobileDropdown() {
    BBExpandCollapse.collapse();

    // hide RTP Awareness component on the modal
    this.$bb.find('li.rtp-awareness').closest('div').hide();

    this.$reservationsDropdownMobile.find('.book-redeem-wrapper').append(this.$bb.detach());
    if (isMobileWidth()) {
      if(window.brand_id != 'wr'){
        this.$bb.find('[name="destination"]:visible').blur();
      }else{
        this.$bb.find('[name="destination"]:visible').focus();
      }
    }
    if($_PAGE_.is('.homepage') && isMobileWidth()){
      $_PAGE_.addClass('scrolled');
    }
  }

  handleBookingBarInHeader() {
    if (isDesktopWidth()) {
      this.putBookingBarIntoDesktopDropdown();
    } else {
      this.putBookingBarIntoMobileDropdown();
    }
    if(window.brand_id  != 'wr' &&  ($('.book-with-points-header'))) {
      $('.book-with-points-header' , this.$bb).append($('.more-options-container div.wyndham-rewards-checkbox',this.$bb).detach());
      $('.wyndham-rewards-checkbox-prompt' , this.$bb).hide();
    }
  }

  handleExtendedHero() {
    this.putBookingBarIntoExtendedHero();
    $(document).on('user:profile:updated', this.putBookingBarIntoExtendedHero.bind(this));
  }
}

export default new BookingBar();
