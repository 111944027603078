import { getBrand } from '../base/session-handler.js';
import {
  _isNull,
  _isNumber,
  formatNumber,
  getNumDays,
  printDollarAmount,
  getCurrencyMapping,
  getSelectedCurencyCode,
  formatDateForPrinting,
  getDateFromDateString,
  convertToCurrency,
  callService,
  formatDateForBWSAvailability,
  isDateInBetween
} from '../base/utils.js';
import BookingSummary from './booking-summary.js';
import { $_PAGE_, reservationDataFromAEM } from '../base/vars.js';
import { getCriteria } from '../base/session-handler.js';

class RateDetails {
  constructor() {
    this.$0 = $('.rate-summary-lightbox');
    this.$taxInfo = this.$0.find('.tax-info');
    this.$feesInfo = this.$0.find('.fees-info');
    this.$feesdisclaimer = this.$0.find('.feesdisclaimer');
    this.$subtotalSpan = this.$0.find('.subtotal .val');
    this.$totalSpan = this.$0.find('.total .val');
    this.$totalCash = this.$totalSpan.find('.cash-total');
    this.$totalPoints = this.$totalSpan.find('.points-total .point-val');
    this.$altTaxDisplay = this.$0.find('.total-tax');
    this.totalBeforePackage = 0;
    this.opts;
    this.currency;
    this.onShowModal();
  }
  async fillTaxes(taxtypes, currency, taxString, brandId, opts,type) {
    if (_isNull(opts)) {
      opts = {};
    }
    this.opts = opts;
    this.currency = currency;
    let totalFee = 0;
    let totalTax = 0;
    this.$feesInfo.find('.estimated-fees').not('.feesTotal').remove();
    this.$taxInfo.find('.estimated-taxes').not('.taxesTotal').remove();
    this.$feesdisclaimer.removeClass('hidden');
    this.$feesInfo.removeClass('hidden');
    opts.packagesTotal = opts.packagesTotal || 0;

    let packagesTotal = 0;

    if (opts.packages && typeof opts.packages == 'string') {
      try {
        packagesTotal = opts.packages ? JSON.parse(opts.packages).map((service) => service.prices)
          .flat(1)
          .map((price) => price.amountAfterTax && parseFloat(price.amountAfterTax))
          .reduce((amount, total) => amount + total, 0) : 0;
      } catch (error) {
        //console.error(error, data.packages);
      }
    }
    if($_PAGE_.is('.confirmation-page') && reservationDataFromAEM.packages){
      try {
        packagesTotal = reservationDataFromAEM.packages ? reservationDataFromAEM.packages.map((service) => service.prices)
          .flat(1)
          .map((price) => price.amountAfterTax && parseFloat(price.amountAfterTax))
          .reduce((amount, total) => amount + total, 0) : 0;
      } catch (error) {
        //console.error(error, reservationDataFromAEM.packages);
      }
    }
    opts.packagesTotal = packagesTotal;

    let dateRateFeeMap = {};
    let taxOrder = {};
    let unProcesseddateRateFeeMap = {};
    if (($_PAGE_.is('.confirmation-page') || $_PAGE_.is('.modify-page')) && !opts.dateRateFeeMap){

      unProcesseddateRateFeeMap = opts.dateRateFeeMap ? JSON.parse(opts.dateRateFeeMap) : opts.dateRateMap ? JSON.parse(opts.dateRateMap) : {};
    } else {
      unProcesseddateRateFeeMap = opts.dateRateFeeMap;
    }

    if(taxtypes && taxtypes.length && (!taxtypes[0].type || !taxtypes[0].effectiveDate)) {
      await callService('detailRetrieve',
        {
          "stayDateRangeStart": ($_PAGE_.is('.confirmation-page') || $_PAGE_.is('.modify-page')) ? formatDateForBWSAvailability(opts.checkInDate) : formatDateForBWSAvailability(getCriteria().checkInDate),
          "stayDateRangeEnd": ($_PAGE_.is('.confirmation-page') || $_PAGE_.is('.modify-page')) ? formatDateForBWSAvailability(opts.checkOutDate) : formatDateForBWSAvailability(getCriteria().checkOutDate) ,
          "hotelCode": ($_PAGE_.is('.confirmation-page') || $_PAGE_.is('.modify-page')) ? opts.propertyId : getCriteria().hotel_id ,
          "adultCount":($_PAGE_.is('.confirmation-page') || $_PAGE_.is('.modify-page')) ? opts.adults : getCriteria().adults ,
          "childCount": ($_PAGE_.is('.confirmation-page') || $_PAGE_.is('.modify-page')) ? opts.children : getCriteria().children,
        }
      ).then(res => {
        console.log(res.roomStays);
        let matches = false;
        if(res.roomStays && res.roomStays[0]){
          $.each(res.roomStays[0].roomRates,(index,items)=>{

            if(items.ratePlanCode == opts.rateCode && items.roomTypeCode == opts.roomType){
              console.log("avilable" ,items.items);
              matches = true;
              if (($_PAGE_.is('.confirmation-page') || $_PAGE_.is('.modify-page'))){
                opts.taxList = JSON.stringify(items.taxes);
              }
              taxtypes = items.taxes;
            }
          }); 
        }
        if(!matches){
          let grouptax = [];
          let uniqueObject = {};
          for (let i in taxtypes) {
            let objTitle = taxtypes[i]['taxType'];
            uniqueObject[objTitle] = taxtypes[i];
            uniqueObject[objTitle]['type'] = (taxtypes[i]['taxType'].toLowerCase().includes('fee') && !taxtypes[i]['taxType'].toLowerCase().includes('package')) ? 'FEE' : 'TAX' ;
          }
          for (let i in uniqueObject) {
            grouptax.push(uniqueObject[i]);
          }
          if (($_PAGE_.is('.confirmation-page') || $_PAGE_.is('.modify-page'))){
            opts.taxList = JSON.stringify(grouptax);
          }
          taxtypes = grouptax;
          console.log("grouptax",grouptax);
        }
      });
    }

   
    if (unProcesseddateRateFeeMap) {
      Object.keys(unProcesseddateRateFeeMap).reverse()
        .forEach( (key) => {
          dateRateFeeMap[key] = unProcesseddateRateFeeMap[key];
        });
    } else {
      dateRateFeeMap = {};
    }
    if (taxtypes) {
        taxOrder = taxtypes.reverse();
    }
    let dateindex = 0;
    $.each(dateRateFeeMap || {}, (date, rate) => {
      let subtotalFee = 0;
      let subtotaltax = 0;
      const divElement = `<div class=" estimated-taxes estimated-fees tax-breakdown-date-rate">
                              <span class="tax-breakdown-date">${formatDateForPrinting(date, 'weekdayCompact')}</span>
                            </div>`;
      $.each(taxOrder, (counter, tt) => {
        tt.taxType = tt.taxType || tt.name || '';
        let titleClass = (tt.taxType || tt.name).replace(/\W/g, '');

        if(((dateindex == 0 && tt.chargeFrequency == '12') || tt.chargeFrequency != '12') && ((($_PAGE_.is('.confirmation-page') && !tt.effectiveDate) || ($_PAGE_.is('.modify-page') && !tt.effectiveDate)) || isDateInBetween(tt.effectiveDate,tt.expireDate,date))){   
          if($_PAGE_.is('.modify-page') && opts && opts.dateRateMap && tt.taxPercent){
            this.$0.find('.nights .num').text(Object.keys(opts.dateRateMap).length);
            if (Object.keys(opts.dateRateMap).length === 1) {
              this.$0.find('.nights .singular').show();
              this.$0.find('.nights .plural').hide();
            } else {
              this.$0.find('.nights .singular').hide();
              this.$0.find('.nights .plural').show();
            }
            this.handleChildren(this.$0.find('.children .num').text());
            this.handleAdults(this.$0.find('.adults .num').text());
          }
          if ((tt.type === 'FEE')) {
            totalFee = totalFee + parseFloat(tt.taxPercent || tt.amount);
            subtotalFee = subtotalFee + parseFloat(tt.taxPercent || tt.amount);
            this.$feesInfo.find('.estimated-fees:first-child').after(`<div class="estimated-fees">
                            <span class="title">${tt.taxType || tt.name}</span>
                            <span class="val">
                            <span class="dollar-amount">${printDollarAmount(parseFloat(tt.taxPercent || tt.amount), currency, true)}</span>
                            </div>`);
          } else if (!_isNull(tt.taxPercent || tt.amount)) {
            let $div = $(document.createElement('div')).addClass('estimated-taxes'),
              $title = $(document.createElement('span')).addClass(titleClass),
              $val = $(document.createElement('span')).addClass('val');

            $title.html(tt.taxType);
            $val.html(`<span class="dollar-amount">${printDollarAmount(parseFloat(tt.taxPercent || tt.amount), currency, true)}</span>`);

            $div.append($title).append($val);
            if (tt.type == 'TAX') {
              totalTax = totalTax + parseFloat(tt.taxPercent || tt.amount);
              subtotaltax = subtotaltax + parseFloat(tt.taxPercent || tt.amount);
              this.$taxInfo.find('.estimated-taxes:first-child').after($div);
            } else if (tt.type === 'FEE') {
              totalFee = totalFee + parseFloat(tt.taxPercent || tt.amount);
              subtotalFee = subtotalFee + parseFloat(tt.taxPercent || tt.amount);
              this.$feesInfo.append(`<div class="estimated-fees">
                                <span class="title">${tt.taxType || tt.name}</span>
                                <span class="val">
                                <span class="dollar-amount">${printDollarAmount(parseFloat(tt.taxPercent || tt.amount), currency, true)}</span>
                                </div>`);
            }
          }

        }
      });
      dateindex = dateindex + 1;
        this.$taxInfo.find('.estimated-taxes:first-child').after(divElement);
        this.$feesInfo.find('.estimated-fees:first-child').after(divElement);
    });

    if (totalTax || taxtypes.length > 0) {
      this.$0.find('.rd-total-roomrate-tax').html(`<span class="total-tax-dollar-amount">${printDollarAmount(parseFloat(totalTax), currency, true)}</span>`);
      this.$taxInfo.removeClass('hidden');
    }else{
      this.$taxInfo.addClass('hidden');
    }
    if (totalFee <= 0) {
      this.$feesInfo.addClass('hidden');
      this.$feesdisclaimer.addClass('hidden');
    } else {
      this.$0.find('.rd-total-roomrate-fees').html(`<span class="total-fees-dollar-amount">${printDollarAmount(parseFloat(totalFee), currency, true)}</span>`);
      this.$feesdisclaimer.removeClass('hidden');

    }

    if ($_PAGE_.is('.confirmation-page')) {
      this.totalBeforePackage = parseFloat(opts.totalBeforeTax || opts.subtotal.replace('$','')) + totalTax + totalFee;
      this.fillTotal(parseFloat(opts.totalBeforeTax || opts.subtotal.replace('$','')), (parseFloat(opts.totalBeforeTax || opts.subtotal.replace('$','')) + totalTax + totalFee + opts.packagesTotal),currency, opts.pacPoints * getCriteria().nights,type);
    } else {
      this.totalBeforePackage = opts.totalBeforeTax + totalTax + totalFee;
      this.fillTotal(opts.totalBeforeTax, (opts.totalBeforeTax + totalTax + totalFee + opts.packagesTotal), currency, opts.pacPoints * getCriteria().nights,type);
    }

  }

  udatePackakageTotal(packagetotal){
    if ($_PAGE_.is('.confirmation-page')) {
      this.fillTotal(parseFloat(this.opts.totalBeforeTax || this.opts.subtotal.replace('$','')),  this.totalBeforePackage + packagetotal ,this.currency, this.opts.pacPoints * getCriteria().nights, 'summary');
    } else {
      this.fillTotal(this.opts.totalBeforeTax, this.totalBeforePackage + packagetotal , this.currency, this.opts.pacPoints * getCriteria().nights,'summary');
    }
  }

  updateRateBreakdown(dateRateMapObj, currencyCode) {
    let propValue,
      $brkdwnheader = this.$0.find('.rate-breakdown .room-nights-header'),
      $brkdwnvalue = this.$0.find('.rate-breakdown .room-nights-value');

    $brkdwnheader.find('li:not(.breakdown-label)').remove();
    $brkdwnvalue.find('li:not(.breakdown-label)').remove();
    // Different screens and locales have different date format, hence need to maintain this map
    let dateToDateStringMap = {};

    let dateArray = Object.keys(dateRateMapObj).map(dateString => {
      let date = getDateFromDateString(dateString);
      dateToDateStringMap[date] = dateString;

      return date;
    });
    dateArray.sort((date1, date2) => date1 - date2);

    dateArray = dateArray.map(d => dateToDateStringMap[d]);

    if ($_PAGE_.is('.booking-page')) {
      for (let propName of dateArray) {
        propValue = dateRateMapObj[propName];
        $brkdwnheader.append(`<li class="">${formatDateForPrinting(propName, 'weekdayCompact')}</li>`);
        $brkdwnvalue.append(`<li class="dollar-amount">${getCurrencyMapping(currencyCode, true) + convertToCurrency(parseFloat(propValue).toFixed(2), currencyCode, true) + ' ' + getSelectedCurencyCode(currencyCode, true)}</li>`);
      }
    } else if ($_PAGE_.is('.confirmation-page,.modify-page')) {
      if (typeof dateRateMapObj === 'object' && !($.isEmptyObject(dateRateMapObj))) { //Checking for modify flow if empty object is passed
        if ($_PAGE_.is('.modify-page')) {
          $brkdwnheader.find('li:not(:first)').remove();
          $brkdwnvalue.find('li:not(:first)').remove();
        }
        for (let propName of dateArray) {
          propValue = dateRateMapObj[propName];
          $brkdwnheader.append(`<li class="">${formatDateForPrinting(propName, 'weekdayCompact')}</li>`);
          $brkdwnvalue.append(`<li class="dollar-amount">${getCurrencyMapping(currencyCode, true) + convertToCurrency(parseFloat(propValue).toFixed(2), currencyCode, true) + ' ' + getSelectedCurencyCode(currencyCode, true)}</li>`);
        }
      } else {
        $('.rate-breakdown').hide();
      }
    }
  }
  fillTotal(subtotalRate, totalRate, currency, points,type) {
    console.log(totalRate);
    this.$subtotalSpan.html(`<span class="dollar-amount">${printDollarAmount(subtotalRate, currency,true)}</span>`).show();
    this.$totalCash.html(`<span class="total-dollar-amount">${printDollarAmount(totalRate, currency, true)}</span>`).show();
    if (points) {
      this.$totalPoints.text(' + ' + formatNumber(points)).parent()
        .show();
    } else {
      this.$totalPoints.parent().hide();
    }
    if(_isNumber(totalRate) && type === 'summary'){
      BookingSummary.handleTotal(totalRate, currency);
    }
    this.$0.find('.subtotal, .taxdisclaimer, .taxdisclaimer + .disclaimer').show();
    // this.updateRateBreakdown();
  }
  handleGoFree(totPoints, optionalTax, optionalCurrency) {
    this.$totalPoints.text(totPoints).parent()
      .show();
    if (optionalTax) {
      this.$totalCash.html(printDollarAmount(optionalTax, optionalCurrency, true)).show();
      this.$totalPoints.text(' + ' + formatNumber(totPoints));
    } else {
      this.$totalCash.hide();
    }
    this.$0.find('.subtotal, .taxdisclaimer, .taxdisclaimer + .disclaimer ,.fees-info ,.feesdisclaimer').hide();
  }
  handleAdults(val) {
    val *= 1;

    this.$0.find('.adults .num').text(val);

    if (val === 1) {
      this.$0.find('.adults .singular').show();
      this.$0.find('.adults .plural').hide();
    } else {
      this.$0.find('.adults .singular').hide();
      this.$0.find('.adults .plural').show();
    }
  }
  handleChildren(val) {
    val *= 1;

    this.$0.find('.children .num').text(val);

    if (val === 1) {
      this.$0.find('.children .singular').show();
      this.$0.find('.children .plural').hide();
    } else {
      this.$0.find('.children .singular').hide();
      this.$0.find('.children .plural').show();
    }
  }
  handleNights(d1, d2) {
    let val = getNumDays(d1, d2);
    if (!(_isNumber(val))) {
      return false;
    }

    this.$0.find('.nights .num').text(val);

    if (val === 1) {
      this.$0.find('.nights .singular').show();
      this.$0.find('.nights .plural').hide();
    } else {
      this.$0.find('.nights .singular').hide();
      this.$0.find('.nights .plural').show();
    }
  }
  handleRooms(val) {
    this.$0.find('.total-rooms .num').text(val);
  }
  handleAll(data) {
    this.handleAdults(data.adults);
    this.handleChildren(data.children);
    this.handleNights(data.checkInDate, data.checkOutDate);
    this.handleRooms(data.rooms);
    let packagesTotal = 0;

    if (data.packages && typeof data.packages == 'string') {
      try {
        packagesTotal = data.packages ? JSON.parse(data.packages).map((service) => service.prices)
          .flat(1)
          .map((price) => price.amountAfterTax && parseFloat(price.amountAfterTax))
          .reduce((amount, total) => amount + total, 0) : 0;
      } catch (error) {
        //console.error(error, data.packages);
      }
    }
    this.fillTaxes(data.taxList, data.currencyCode, data.taxTotal, getBrand(), {
      subtotal: data.totalBeforeTax,
      total: data.totalAfterTax,
      totalAverageToDisplay: data.totalAverageToDisplay,
      packagesTotal: packagesTotal,
      ...data
    },'summary');
    //this.fillTotal(data.totalBeforeTax, data.totalAfterTax, data.currencyCode);
  }
  onShowModal() {
    $('#rateSummaryDetail').on('shown.bs.modal', () => {
      $('.rate-summary-lightbox .modal-content').animate({
        scrollTop: 0
      }, 'fast');
    });
  }
}

export default new RateDetails();
