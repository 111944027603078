import { _LOCALE_, _LANGUAGE_ } from '../base/vars.js';
import { exists } from '../base/dom-utils.js';
import CollectionOfHotels from '../pages/collection-hotels.js';
import RenderFavourite from './render-favourite.js';
import { getTripAdvisorIFrame } from '../base/utils.js';
import Analytics from '../base/wyn-analytics-module.js';

class FeaturedCard {
  constructor() {
    this.handleClickAnalytics();
    this.initCarousel();
    const crrPath = location.pathname;
    const crrResourcePath = crrPath.split('.')[0];
    this.collectionOfHotels = new CollectionOfHotels();
    this.collectionOfHotels.fetchDynamicProperties(crrResourcePath, _LOCALE_);
    this.payload = {};
    if (exists('.featurecard')) {
      this.handleDynamicCards();
    }
  }

  async getStarAmenities() {
    const data = await this.fetchOtherAmenities();
      return data;
  }
  async fetchOtherAmenities() {
    const url = '/bin/getStarAmenities';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  initCarousel() {
    if ($('.featured-card .featured-card-carousel').length > 0) {
      $('.featured-card .featured-card-carousel')
        .not('.slick-initialized')
        .slick({
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 0,
          responsive: [
            {
              breakpoint: 1199,
              settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 719,
              settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
              },
            },
            {
              breakpoint: 374,
              settings: {
                dots: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
              },
            },
          ],
        });
    }
  }

  async handleDynamicCards() {
    let imgIdsArr = [];
    let requestObject = {};
    const propertiesObj = [];
    const featurecardInput = document.querySelector('.featured-card .dynamicFeaturedId');
    const collectionTypeInput = document.querySelector('.featured-card .collectionType');
    const dynamicIdsValue = featurecardInput.value;
    const collectionTypeValue = collectionTypeInput.value;
    const dynamicIdArr = dynamicIdsValue.split(',');
  
    if (collectionTypeInput && collectionTypeValue !== '') {
      requestObject.collectionType = collectionTypeValue;
    }
  
    if (featurecardInput && dynamicIdsValue !== '') {
      try {
        const res = await this.collectionOfHotels.callCollectionAPIFeatured(dynamicIdArr);
        const dynamicIdscollection = this.collectionOfHotels.renderFcDynamicContent(dynamicIdArr, res.data.searchProperties.properties);
        dynamicIdscollection.forEach((item) => {
          imgIdsArr.push({ brandId: item.brand.toLowerCase(), propertyId: item.propertyId });
          const amentiesArr = item.otherAmenities.map((item) => item.amenityName);
          const obj = {
            "language": _LANGUAGE_,
            startAmenities: amentiesArr,
            propertyId: item.propertyId
          };
          propertiesObj.push(obj);
        });
  
        this.payload = propertiesObj;
        const resAmenties = await this.getStarAmenities();
        const keys = Object.keys(resAmenties);
  
        keys.forEach((itemId) => {
          dynamicIdscollection.forEach((data) => {
            if (data.propertyId === itemId) {
              data['amenityName'] = resAmenties[itemId]['amenityName'];
            }
          });
        });
  
        requestObject.properties = imgIdsArr;
        const resImgTagging = await this.collectionOfHotels.getImageTaggingData(requestObject);
        const featuredCardContainer = document.querySelector('.featured-card-container');
  
        if (featuredCardContainer) {
          dynamicIdscollection.forEach(cardItem => this.renderFeatureCard(cardItem, featuredCardContainer, resImgTagging));
          this.initCarousel();
          this.collectionOfHotels.handleQuickViewModal('.featured-card', '.quick-view-button');
        }
      } catch (error) {
        console.error('Error handling dynamic cards:', error);
      }
    }
  }
  getRecommendation(cardItem) {
    const additionalDetails = cardItem.additionalDetails;
    if (!Array.isArray(additionalDetails)) {
      return 'All Inclusive'; // or any other default string
    }
    const detail = additionalDetails.find(
      ({ name, values }) => name === 'hotelQualityScoreRequirementFlag' && Array.isArray(values) && values.includes('Y')
    );
    return detail ? 'Guest Recommended' : cardItem['amenityName'] ? cardItem['amenityName'] : "";
  }
  
  renderFeatureCard(cardItem, container, resData) {
    const cardListElement = document.createElement('div');
    cardListElement.classList.add('featured-card-list');

    const cardImageElement = document.createElement('div');
    cardImageElement.classList.add('featured-card-image');

    const carouselElement = document.createElement('div');
    carouselElement.classList.add('featured-card-carousel');
    cardImageElement.appendChild(carouselElement);

    const eyebrowElement = document.createElement('div');
    eyebrowElement.classList.add('featured-card-eyebrow');
    eyebrowElement.textContent = this.getRecommendation(cardItem);
    this.getRecommendation(cardItem) && cardImageElement.appendChild(eyebrowElement);
    RenderFavourite.fetchFavourite(cardImageElement, cardItem.propertyId);
    const cardDetailsElement = document.createElement('div');
    cardDetailsElement.classList.add('featured-card-details');

    const headlineElement = document.createElement('h3');
    headlineElement.classList.add('headline-h');
    headlineElement.textContent = cardItem.name;
    cardDetailsElement.appendChild(headlineElement);

    const descriptionElement = document.createElement('div');
    descriptionElement.classList.add('featured-card-description');

    const locationElement = document.createElement('p');
    locationElement.classList.add('location-text');
    locationElement.textContent = `${cardItem.address.city}, ${cardItem.address.state}`;
    descriptionElement.appendChild(locationElement);

    const ratingElement = document.createElement('div');
    ratingElement.classList.add('rating');
    const sanitizedtaIframe = getTripAdvisorIFrame(cardItem.propertyId);
    ratingElement.innerHTML = sanitizedtaIframe;
    descriptionElement.appendChild(ratingElement);

    const quickViewBtnElement = document.createElement('a');
    quickViewBtnElement.classList.add('btn-secondary', 'quick-view-button');
    quickViewBtnElement.href = DOMPurify.sanitize(cardItem.uniqueUrl);
    quickViewBtnElement.dataset.id = DOMPurify.sanitize(cardItem.propertyId);
    quickViewBtnElement.textContent = 'Quick View';

    cardDetailsElement.appendChild(descriptionElement);
    cardDetailsElement.appendChild(quickViewBtnElement);

    cardListElement.appendChild(cardImageElement);
    cardListElement.appendChild(cardDetailsElement);

    const imageUrls = resData[cardItem.propertyId] || [];
    imageUrls.forEach((imageUrl) => {
      const pictureElement = document.createElement('picture');

      const sourceTablet = document.createElement('source');
      sourceTablet.media = '(min-width: 768px)';
      sourceTablet.srcset = imageUrl.tablet;
      pictureElement.appendChild(sourceTablet);

      const sourceMobile = document.createElement('source');
      sourceMobile.media = '(max-width: 767px)';
      sourceMobile.srcset = imageUrl.mobile;
      pictureElement.appendChild(sourceMobile);

      const imageItem = document.createElement('img');
      imageItem.alt = imageUrl.altText;
      imageItem.src = imageUrl.desktop;
      imageItem.classList.add(
        'pll-image-full-size',
        'lazy-load-pageload',
        'carousel-img',
        'featured-card-carousel-image'
      );
      pictureElement.appendChild(imageItem);

      carouselElement.appendChild(pictureElement);
    });

    container.appendChild(cardListElement);
  }

  handleClickAnalytics() {
    const carouselArrows = document.querySelectorAll('.featured-card .slick-arrow');
    if(carouselArrows.length > 0) {
      carouselArrows.forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          Analytics.satelliteTracker('carausalArrowClick');
        });
      });
    }
  }
}

export default new FeaturedCard();
