import {
  exists,
  addMobileOverflowFix,
  removeMobileOverflowFix,
  isDesktopWidth, isTabletWidth, isMobileWidth
} from '../../base/dom-utils.js';
import {
  EventHandler,
  getRetailBannerHeight,
  getHeaderHeight,
} from '../../base/utils.js';
import {
  $_HEADER_,
  $_PAGE_,
  brand_id
} from '../../base/vars.js';

class HeaderMobile {
  constructor() {
    this.bindDropdowns();
    this.setupViewForRetailBanner();
  }

  getMobileOrTabletMenuMarginTop() {
    let dropdownMargin;
    if ((brand_id == 'wr' && $_PAGE_.is('.homepage-template')) && exists('#smartbanner')) {
      dropdownMargin = $('#smartbanner').height() + getRetailBannerHeight();
    } else if($('#pageHeader').is('.old-navigation-hide')) {
      dropdownMargin = getHeaderHeight();
    } else {
      dropdownMargin = getRetailBannerHeight();
    }
    return dropdownMargin;
  }

  setupViewForRetailBanner() {
    if (isTabletWidth() || isMobileWidth()) {
      let dropdownMargin = this.getMobileOrTabletMenuMarginTop();
      EventHandler.on('retailbanner:loaded', () => {
        dropdownMargin = this.getMobileOrTabletMenuMarginTop();
        $('header.mobile-nav .dropdown').css('marginTop', dropdownMargin);
      });
    }
  }

  bindDropdowns() {
    const context = this;
    $(document).on('click', 'header.mobile-nav .top-nav-item', (e) => {
      if (!isDesktopWidth()) {
        let $headerMobileNav = $('header.mobile-nav'),
          $toggle = $(e.currentTarget),
          dropdownName = $(e.currentTarget).data('dropdown'),
          open = $(e.currentTarget).hasClass('open');


        const dropdownMarginVal = context.getMobileOrTabletMenuMarginTop();
        if($_HEADER_.is('.old-navigation-hide')){
          $('header.mobile-nav .dropdown').css('marginTop', dropdownMarginVal);
        }
        this.closeDropdowns();

        $('header.mobile-nav .panel-main').show();

        if (!open) {
          let $dropdown = $headerMobileNav.find('.dropdown[data-dropdown=' + dropdownName + ']');

          if (isMobileWidth() && dropdownName == 'book') {
            $dropdown.css('marginTop', 0);
          }
          $dropdown.slideDown('fast', () => {
            $toggle.addClass('open');
            $dropdown.attr('aria-expanded', true);
            addMobileOverflowFix();
            if (dropdownName !== 'book') {
              // Booking dropdown uses iOS auto-scroll on input focus. Don't override it.
              window.scrollTo(0, 0);
            }
          });

          if (brand_id != 'wr' && (dropdownName == 'book' || dropdownName == 'account')){
            $dropdown.css({"display": "flex"});
          }
          if(dropdownName == 'book'){
            $dropdown.trigger('wr:dropdown:open');
          } else {
            if($('.booking-bar').hasClass('open')){
              $('#magnifyingGlassButton').triggerHandler('click');
            }
          }

          if (brand_id != 'wr') {
            $('#pageHeadernew').removeClass('header-height-nav');
            $('#pageHeadernew').css('z-index', 101);
          }

        }else {
          if (isMobileWidth() && dropdownName == 'book' && !$_PAGE_.is('.homepage')) {
            $('#magnifyingGlassButton').triggerHandler('click');
          }
        }
      }
    });
  }

  closeDropdowns() {
    let $headerMobileNav = $('header.mobile-nav'),
      $dropdowns = $headerMobileNav.find('.dropdown:visible'),
      $topNavItem = $headerMobileNav.find('.top-nav-item');

    removeMobileOverflowFix();
    $topNavItem.removeClass('open');

    // jQuery doesn't pass the element reference back here; need to use "this"
    $($dropdowns).slideUp('fast', function() {
      $(this).attr('aria-expanded', false);
      $(this).trigger('wr:dropdown:closed');
      $dropdowns.removeClass('panel-open');
      $headerMobileNav.find('.panel-current').removeClass('panel-current');
    });

    if (brand_id != 'wr') {
      $('#pageHeadernew').addClass('header-height-nav');
      $('#pageHeadernew').css('z-index', 99);
    }
  }
}

export default new HeaderMobile();
