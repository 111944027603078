import { UserHandler } from './session-handler.js';
import { uppercaseFirstLetterEachWord } from './utils.js';
import { _LOCALE_, property_country_code } from './vars.js';

function populateForm(userInfo) {
  if (userInfo) {
    $('.wyndham-rewards .login-input, .wyndham-rewards .continue').remove();
  }
  userInfo = userInfo || {};
  userInfo = {
    fname: userInfo.fname || '',
    lname: userInfo.lname || '',
    address: (userInfo.add1 || ''),
    address2: (userInfo.add2 || '') + ' ' + (userInfo.add3 || ''),
    city: userInfo.city || '',
    country: userInfo.country || '',
    state: userInfo.state || '',
    zip: userInfo.zip || '',
    email: userInfo.email || '',
    phone: userInfo.phone || '',
    wrNo: userInfo.wrNo || ''
  };

  if ($('.name').length) {
    namePopulate(userInfo);
  }
  if ($('.billing-address').length) {
    addressPopulate(userInfo);
  }
  if ($('.contact-details').length) {
    contactPopulate(userInfo);
  }
  $('input[name=customerWRNumber]').val(userInfo.wrNo);
  $('input[name=rewardNumber]').val(userInfo.wrNo);

}
function namePopulate(userInfo) {
  $('.first-name input').val(uppercaseFirstLetterEachWord(userInfo.fname));
  $('.last-name input').val(uppercaseFirstLetterEachWord(userInfo.lname));
}
function addressPopulate(userInfo) {
  $('.street input').val(uppercaseFirstLetterEachWord(userInfo.address.trim()));
  $('.street2 input').val(uppercaseFirstLetterEachWord(userInfo.address2.trim()));
  $('.city input').val(uppercaseFirstLetterEachWord(userInfo.city));
  if (userInfo.country != '')
    $('.country select').val(userInfo.country);
  countryChange($('.state-province select'), userInfo.country);
  if (userInfo.country === 'US' || userInfo.country === 'CA') {
    $('.state-province select').val(userInfo.country + '_' + userInfo.state);
  }
  $('.postcode input').val(userInfo.zip);
}
function contactPopulate(userInfo) {
  $('.email input, .confirm-email input').val(userInfo.email.toLowerCase());
  $('.phone input').val(userInfo.phone);
}

// Handles enabling/disabling the state input when user selects a country
function countryChange($stateSelect, country) {
  if (country === 'CA' || country === 'US' || country === '') {
    if ($stateSelect.val() === 'NA') {
      // Reset to default value if changing to a country with states.
      $($stateSelect.find('option')[0]).prop('selected', true);
    }
    $stateSelect.prop('disabled', false);
  } else {
    $stateSelect.val('NA');
    $stateSelect.prop('disabled', true);
  }

  if ($('.special-requests').length) {
    if (country === 'CN' || country === 'US') {
      $('.special-requests input[type="checkbox"]').prop('checked', true);
    } else {
      $('.special-requests input[type="checkbox"]').prop('checked', false);
    }
  }

  const wyndhamRewardsElement = $('.wyndham-rewards');

  if (wyndhamRewardsElement.length) {
    const isBrandParticipatingToWR = wyndhamRewardsElement.data('cmpWrParticipation');
    let totax = window.req_after_tax;
    let req_auto_enroll = window.req_auto_enroll || '',
      req_qual_points = window.req_qual_points || '';


    if (req_auto_enroll === 'true' || req_auto_enroll === true) {
      req_auto_enroll = true;
    } else if (req_auto_enroll === 'false' || req_auto_enroll === false) {
      req_auto_enroll = false;
    } else {
      req_auto_enroll = false;
    }

    let isSelectCountryUS = (($('.country select').val() === 'US') || ($('.country select').val() === '') || ($('.country select').val() === null));
    let isVariantUS = _LOCALE_ === 'en-us';
    //DIG-1647 - show/hide points/no-points description
    $('.point-replace.description.us,.point-replace.description.non-us, .wyndham-rewards .points-desc, .wyndham-rewards .nopoints-desc').css('display', 'none');
    //logged user
    if(window.brand_id != "ww"){
    if (UserHandler.isWRLoggedIn()) {
      // logged in user with US property
      if ((property_country_code === 'US') && (req_qual_points > 0) && (req_auto_enroll === false) && !(totax <= 25)) {
        $('.wyndham-rewards .points-desc').css('display', 'inline-block');
      } else if ((property_country_code === 'US') && (req_qual_points > 0) && (req_auto_enroll === true) && !(totax <= 25)) {
        $('.wyndham-rewards .points-desc').css('display', 'inline-block');
      } else if ((property_country_code === 'US') && (req_qual_points <= 0)) {
        $('.wyndham-rewards .points-desc').css('display', 'none');
      } else if (!(property_country_code === 'US') && (req_qual_points > 0) && (req_auto_enroll === false)) { // logged in user with Non-US property
        $('.wyndham-rewards .nopoints-desc').css('display', 'inline-block');
      } else if (!(property_country_code === 'US') && (req_qual_points > 0) && (req_auto_enroll === true)) {
        $('.wyndham-rewards .nopoints-desc').css('display', 'inline-block');
      } else if (!(property_country_code === 'US') && (req_qual_points <= 0)) {
        $('.wyndham-rewards .nopoints-desc').css('display', 'none');
      }
      $('.wyndham-rewards .checkbox').hide();
      $('.wyndham-rewards .mktg-wr').hide();
    } else { //Guest user
      $('.wyndham-rewards .checkbox').show();

      if (isSelectCountryUS && isVariantUS) {
        $('input[name="enroll"]').prop('checked', isBrandParticipatingToWR);
        $('.mktg-wr input[type="checkbox"]').prop('checked', true);
        $('.mktg-wr').addClass('hide');
      } else {
        $('input[name="enroll"]').prop('checked', false);
        $('.mktg-wr input[type="checkbox"]').prop('checked', false);
        $('.mktg-wr').removeClass('hide');
      }
      $('#enroll').trigger('change');
      if (req_auto_enroll === true) {
        $('.wyndham-rewards').find('#enroll')
          .parsley()
          .reset();
        $('input[name="enroll"]').prop('required', 'required');
      }

      if ((property_country_code === 'US') && isSelectCountryUS) {
        //Guest with US property with US user
        if (req_qual_points > 0 && req_auto_enroll === false && !(totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display', 'inline-block');
        } else if (req_qual_points > 0 && req_auto_enroll === true && !(totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display', 'inline-block');
          $('.point-replace.description.us').css('display', 'inline-block');
          $('.wyndham-rewards').removeClass('non-us');
        } else if (req_qual_points > 0 && req_auto_enroll === true && (totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display', 'none');
          $('.point-replace.description.us').css('display', 'inline-block');
          $('.wyndham-rewards').removeClass('non-us');
        } else if (req_qual_points <= 0) {
          $('.wyndham-rewards .points-desc').css('display', 'none');
        }
      } else if ((property_country_code === 'US') && !isSelectCountryUS) {
        //Guest with US property with Non-US user
        if (req_qual_points > 0 && req_auto_enroll === false && !(totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display', 'inline-block');
        } else if (req_qual_points > 0 && req_auto_enroll === true && !(totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display', 'inline-block');
          $('.point-replace.description.non-us').css('display', 'inline-block');
          $('.wyndham-rewards').addClass('non-us');
        } else if (req_qual_points > 0 && req_auto_enroll === true && (totax <= 25)) {
          $('.wyndham-rewards .points-desc').css('display', 'none');
          $('.point-replace.description.non-us').css('display', 'inline-block');
          $('.wyndham-rewards').addClass('non-us');
        } else if (req_qual_points <= 0) {
          $('.wyndham-rewards .points-desc').css('display', 'none');
        }
      } else if (!(property_country_code === 'US') && isSelectCountryUS) {
        //Guest with Non-US property with US user
        if (req_qual_points > 0 && req_auto_enroll === false) {
          $('.wyndham-rewards .nopoints-desc').css('display', 'inline-block');
        } else if (req_qual_points > 0 && req_auto_enroll === true) {
          $('.wyndham-rewards .nopoints-desc').css('display', 'inline-block');
          $('.point-replace.description.us').css('display', 'inline-block');
          $('.wyndham-rewards').removeClass('non-us');
        } else if (req_qual_points <= 0) {
          $('.wyndham-rewards .nopoints-desc').css('display', 'none');
        }
      } else if (!(property_country_code === 'US') && !isSelectCountryUS) {
        //Guest with Non-US property - Non-US user
        if (req_qual_points > 0 && req_auto_enroll === false) {
          $('.wyndham-rewards .nopoints-desc').css('display', 'inline-block');
        } else if (req_qual_points > 0 && req_auto_enroll === true) {
          $('.wyndham-rewards .nopoints-desc').css('display', 'inline-block');
          $('.point-replace.description.non-us').css('display', 'inline-block');
          $('.wyndham-rewards').addClass('non-us');
        } else if (req_qual_points <= 0) {
          $('.wyndham-rewards .nopoints-desc').css('display', 'none');
        }
      }
    }
    }


    // handle T&C US / Non-US logic
    let mktgWhg = $('.mktg-whg');
    if (isSelectCountryUS && isVariantUS) {
      mktgWhg.addClass('hide');
      mktgWhg.find('input[type="checkbox"]').prop('checked', true);
    } else {
      mktgWhg.removeClass('hide');
      mktgWhg.find('input[type="checkbox"]').prop('checked', false);
    }

    resetEnrollRequired();
  }
}

function stateChange($countrySelect, state) {
  if ((!state) || (state == 'NA')) {
    return;
  }
  let country = state.split('_')[0];
  $countrySelect.val(country).change();
}

function resetEnrollRequired() {
  let req_auto_enroll = window.req_auto_enroll || '';
  let customerWRN = $('#customerWRN');
  customerWRN.off('blur'); // prevent multiple listeners
  customerWRN.on('blur', resetEnrollRequired); // updated listener
  if ((req_auto_enroll === true) && (customerWRN.val() !== '')) {
    $('.wyndham-rewards #enroll').removeAttr('required');
  }
}

export {
  populateForm,
  countryChange,
  stateChange
};
